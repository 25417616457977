<template>
  <main class="page shares-main">
    <BreadcrumbsComponent title="Акции" />
    <div class="container-padding">
      <div class="page__inner">
        <div v-if="shares && shares.length" class="shares-main__grid">
          <ShareCardComponent v-for="(share, i) in shares" :key="i" :share="share" />
        </div>
        <PaginationComponent
          v-if="Math.ceil(total / first) > 1"
          :page="page"
          :total="Math.ceil(total / first)"
          @change="reload"
        />
      </div>
    </div>
  </main>
</template>

<script>
import ShareCardComponent from "components/ShareCardComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SHARES_PAGE from "@/graphql/pages/SharesPage.graphql";
import PaginationComponent from "components/Pagination.vue";
export default {
  name: "SharesPage",
  data() {
    return {
      page: 1,
      first: 6,
    };
  },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: SHARES_PAGE,
        variables: {
          first: 6,
          page: 1,
        },
      })
      .then(({ data }) => {
        store.state.shares_page = data.shares_paginate;
      });
  },
  methods: {
    async reload(page = 1) {
      await this.$apolloProvider.defaultClient
        .query({
          query: SHARES_PAGE,
          variables: {
            page: page,
            first: this.first,
          },
        })
        .then(({ data }) => {
          this.$store.state.shares_page = data.shares_paginate;
          this.page = page;
        });
    },
  },
  computed: {
    shares() {
      return this.$store.state.shares_page.data;
    },
    total() {
      return this.$store.state.shares_page.paginatorInfo.total;
    },
  },
  components: { PaginationComponent, BreadcrumbsComponent, ShareCardComponent },
};
</script>

<style lang="stylus">
.shares-main{
  &__grid{
    display grid
    gap 30px
    grid-template-columns repeat(3, 1fr)

    +below(1024px){
      grid-template-columns repeat(1, 1fr)
    }
  }
}
</style>
